function Subheader ({heading,paragraph, styles}){
    return (
        <section>
            <article className="container mx-auto">
    <div className=" flex flex-col justify-evenly
     w-full md:w[80%] text-white mx-auto px-10">
        <h1 className={styles.header}>{heading}</h1>
        <p className={styles.paragraph}>{paragraph}</p>
    </div>
    </article>
        </section>
    )
    }
    
    export default Subheader;