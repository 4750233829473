import Image from '../../assets/spiralbg.png'


export function Renvest (){
    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">

        <div className="flex h-[30rem] flex-col justify-center py-4 items-center ">
           <h1 className="text-xl font-bold text-[#3a7700]  text-left lg:text-3xl leading-10">Our mission is to create more economic freedom in the world</h1>
           <p className="text-base lg:text-lg mt-6 text-justify leading-8 leading-10">We define economic freedom as the ability to make choices with respect to one’s personal resources, unencumbered by trusted third parties or borders or lack of access. We believe economic freedom is the foundation of peace and prosperity, and by creating more of it for people, we are reducing suffering in the world. Our mission is to help everyone, everywhere be more economically free</p>
           </div>   
          <div className="h-[30rem]">
           <img src={Image} alt="sideimage" className=" h-full w-full object-contain"/>
           </div>  
           </div>  
    )
}

export function Renswap (){
    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
<div className="hidden lg:block h-[30rem]">
           <img src={Image} alt="sideimage" className=" h-full w-full object-contain"/>
           </div> 
        <div className="flex h-[30rem] flex-col  justify-center py-4 items-center ">
           <h1 className="text-xl font-bold text-[#3a7700] text-left lg:text-3xl leading-10">Our mission is to create more economic freedom in the world</h1>
           <p className="text-lg  text-justify mt-6 leading-8 lg:leading-10">We define economic freedom as the ability to make choices with respect to one’s personal resources, unencumbered by trusted third parties or borders or lack of access. We believe economic freedom is the foundation of peace and prosperity, and by creating more of it for people, we are reducing suffering in the world. Our mission is to help everyone, everywhere be more economically free</p>
           </div>   
           <div className="block lg:hidden h-[30rem]">
           <img src={Image} alt="sideimage" className=" h-full w-full object-contain"/>
           </div> 
           </div>  
    )
}