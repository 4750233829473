import sanityClient from "../../../lib/sanity/sanity.server";
import { postQuery, authorQuery } from "../../../api/api";
import { Fragment, useEffect, useState } from "react";
import NewsUI from "./PostUI";

function NewsLogic() {
  const [post, setPost] = useState({});

  async function fetchNews() {
    try {
      const post = await sanityClient.fetch(postQuery);
      const author = await sanityClient.fetch(authorQuery);

      const [postData, authorData] = await Promise.all([post, author]);
      setPost(() => {
        return { postData, authorData };
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchNews();
  }, []);

  const displayOtherNews = () => {
    return post.postData?.map((cur) => {
      return (
        <Fragment key={cur._id}>
          <NewsUI post_data={cur} author={post.authorData} />
        </Fragment>
      );
    });
  };

  return (
    <section>
      <article className='container mx-auto h-full mt-12'>
        <div className='w-full flex justify-center items-center py-6'>
          <h1 className='text-4xl font-bold heading'>LATEST NEWS</h1>
        </div>
        <div className=''>
          <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-6 gap-x-2 gap-y-6 px-4 w-full mx-auto'>
            {/* <NewsUI /> */}
            {displayOtherNews()}
          </div>
          <div className='w-56 h-full mx-auto mt-12 mb-4 py-4 flex justify-center items-center'>
            <a
              href='https://blog-rho-ashy.vercel.app/'
              className='px-10 btn text-bold py-4 rounded hover:shadow-lg hover:scale-1.5'
              target={"_blank"}
              rel='noreferrer'>
              Read More News
            </a>
          </div>
        </div>
      </article>
    </section>
  );
}

export default NewsLogic;
