import { Fragment } from "react";
import { Link } from "react-router-dom";

// import Image from "next/image";
import Logo from "../../assets/logo.png";
// import Logo from "../assets/logo.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCoffee, farFaceb } from "@fortawesome/free-solid-svg-icons";
// import image from "c";

function Footer() {
  return (
    <Fragment>
      <footer className='bg-[#003356]'>
        <section className='container mx-auto flex flex-col lg:flex-row lg:justify-evenly lg:items-center p-6  lg:h-80 lg:pt-24 '>
          <article className='w-full lg:w-[40%]'>
            <div className='mb-14 mt-10 lg:mt-0 lg:mb-0 flex flex-col justify-center items-evenly'>
              <div className='w-52 h-16 mx-auto'>
                <img src={Logo} alt='Rencoin' width='100%' height='100%' />
              </div>
              <p className='text-lg text-center mt-1 text-white'>
                THE REVOLUTIONARY COIN
              </p>
            </div>
          </article>
          <article className='w-full justify-items-center lg:w-[60%] grid grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-5 lg:h-full'>
            <div className='h-full'>
              <ul>
                <p className='text-lg lg:text-xl font-bold text-white'>
                  Rencoin
                </p>
                <li className=' text-lg text-white my-3'>
                  <Link to='/why-rencoin' className='text-base lg:text-lg'>
                    Why Rencoin?
                  </Link>
                </li>
                <li className=' text-lg text-white'>
                  <Link to='/how-it-works' className='text-base lg:text-lg'>
                    How It Works
                  </Link>
                </li>
                <li className=' text-md text-white my-3'>
                  <Link to='/' className='text-base lg:text-lg'>
                    Knowledge Base
                  </Link>
                </li>
                <li className=' text-md text-white my-3'>
                  <Link to={"/"} className='text-base lg:text-lg'>
                    Transparency
                  </Link>
                </li>
                <li className=' text-md text-white '>
                  <Link to="/" className='text-base lg:text-lg'>
                    Fees
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <p className='text-lg lg:text-xl font-bold text-white'>
                  Company
                </p>
                <li className=' text-md text-white my-3'>
                  <Link to={"/about-us"} className='text-base lg:text-lg'>
                    About Us
                  </Link>
                </li>
               
                <li className=' text-md  text-white'>
                  <Link to="/" className='text-base lg:text-lg'>
                    Legal Terms
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <p className='text-lg lg:text-xl font-bold text-white'>
                  Resources
                </p>
                <li className='text-md text-white my-3'>
                  <a href="https://blog-rho-ashy.vercel.app/" className='text-base lg:text-lg'>Blog</a>
                  
                </li>
                <li className=' text-md  text-white '>
                  <Link to="/faq" className='text-base lg:text-lg'>
                    FAQs
                  </Link>
                </li>
                
                <li className=' text-md my-3  text-white'>
                  <Link to='/' className='text-base lg:text-lg'>
                    Whitepaper
                  </Link>
                </li>
              </ul>
            
            </div>
            <div>

<ul>
  <p className='text-lg lg:text-xl font-bold text-white'>
    Contact Us
  </p>
  <li className=' text-md text-white my-3'>
    <a href="https://blog-rho-ashy.vercel.app/" className='text-base lg:text-lg'>Facebook</a>
    
  </li>
  <li className=' text-md  text-white my-3'>
    <Link to={"/faq"} className='text-base lg:text-lg'>
      Twitter
    </Link>
  </li>
  
  <li className=' text-md  text-white my-3' >
    <Link to='/' className='text-base lg:text-lg'>
      Instagram
    </Link>
  </li>
  <li className=' text-md  text-white my-3'>
    <Link to='/' className='text-base lg:text-lg'>
      {" "}
      Telegram
    </Link>
  </li>
  <li className=' text-md  text-white my-3'>
    <Link to='/' className='text-base lg:text-lg'>
      {" "}
      Youtube
    </Link>
  </li>
</ul>
</div>
          </article>
        </section>
        <section className='container mx-auto w-full px-2 h-full lg:mt-6 lg:py-4'>
          <div className='mx-auto w-52 h-16 flex justify-center items-center mb-1 '>
            <img src={Logo} alt='Rencoin' />
          </div>
          {/* SOCIAL MEDIA LINKS */}
          <div>{/* <FontAwesomeIcon icon={faCoffee} /> */}</div>
          {/* COPY RIGHT */}
          <div className='w-full text-center text-md text-white py-2 h-16'>
            <span> {new Date().getFullYear()}</span>
            <span> Tratrust Limited. All rights reserved.</span>
          </div>
        </section>
      </footer>
    </Fragment>
  );
}

export default Footer;
