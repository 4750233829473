import { useState } from "react";
import { CaretUp } from "../../../assets/svg/Svg";



function FAQFuntions({data}) {
  const [activeIndex, setIndex] = useState(null);
  return data?.map(({ id, question, answer }, idx) => {
    function getIndex(id) {
      setIndex(id);
    }

    const active = activeIndex === idx ? "slide-out" : "slide-in ";
    const open = activeIndex === idx ? "" : "overflow-y-hidden ";
    const rotate = activeIndex === idx ? "rotate-180" : "";
    return (
      <div
        key={id}
        class={`accordion-item  transition-all h-full rounded-none bg-white  ${open}`}
        onClick={() => getIndex(idx)}>
        <button
          class='accordion-button
          h-full
          relative
          my-auto
          flex
          items-center
          justify-between
          w-full
          py-5
  px-10
  text-base text-gray-800 text-left
  bg-white
  rounded-none
  transition
  border-b border-gray-200
  focus:outline-none'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#flush-collapseOne'
          aria-expanded='false'
          aria-controls='flush-collapseOne'>
          <h6>{question}</h6>
          <CaretUp change={rotate} />
        </button>
        <div
          id='flush-collapseOne'
          class='order-0'
          aria-labelledby='flush-headingOne'>
          <div
            class={`accordion-body py-2 px-10 text-sm text-gray-800 text-left
 leading-8  ${active}`}>
            {answer}
          </div>
        </div>
      </div>
    );
  });
}

export default FAQFuntions;
