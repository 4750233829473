import React from "react";
import { MobileNav, DesktopNav } from "./Navigation";

const Header = () => {
  return (
    <header
      className='h-full w-screen overflow-x-hidden'
      style={{
        backgroundImage: "linear-gradient(90deg,#3a7700,#003356)",
      }}>
      <div className='container mx-auto'>
        <nav className='w-full h-24'>
          <MobileNav />
          <DesktopNav />
        </nav>
      </div>
    </header>
  );
};

export default Header;
