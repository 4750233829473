import React, { useState } from "react";
import Image from "../../../assets/swap.webp";
import SwapButton from "./SwapButton";
import SwapUI from "./SwapInput";

function SwapContent() {
  const [active, setActive] = useState(false);

  function getBuyUI() {
    setActive(false);
  }

  function getSellUI() {
    setActive(true);
  }

  return (
    <div className='flex items-center justify-evenly'>
      <div className='w-[50%] hidden lg:block'>
        <img src={Image} alt='wallet' className='w-[80%] h-[80%] object-fill' />
      </div>
      <div className='w-full md:w-[60%] lg:w-[50%]'>
        <div className='w-[90%] lg:w-[29rem] rounded-lg bg-[#f1f7fd] shadow-lg h-full mx-auto'>
          <div>
            <SwapButton
              styles={`${
                !active
                  ? "bg-[#003356] text-[#f1f7fd]"
                  : "bg-transparent text-black"
              } w-1/2 py-5 text-md font-bold rounded-lg `}
              toggle={getBuyUI}
              // onClick={getBuyUI}
            >
              BUY
            </SwapButton>
            <SwapButton
              styles={` ${
                !active
                  ? "bg-transparent text-black"
                  : "bg-[#003356] text-[#f1f7fd]"
              } w-1/2 py-5 rounded-lg text-md font-bold`}
              toggle={getSellUI}>
              SELL
            </SwapButton>
          </div>
          <div className='w-full py-5 '>
            <SwapUI active={active} />
          </div>
        </div>
      </div>
    </div>
  );
}
function SwapLogic() {
  return (
    <section className=' h-full md:mt-32 lg:mt-0 py-10 w-screen'>
      <div className='w-3/4 md:w-2/3 lg:w-1/2 mx-auto text-center'>
        <h1 className='text-3xl md:text-4xl lg:text-5xl font-bold text-center lg:leading-[3.5rem] mt-6 leading-[2.8rem] lg:px-0 text-[#3a7700]'>
          Buy quickly and easily
        </h1>
        <h3 className='text-base md:text-md text-center font-medium leading-7 lg:leading-8 text-[#3a7700] px-2 md:px-0 my-6'>
          Use your credit card, payment app, or bank account to buy Bitcoin,
          Bitcoin Cash, Ethereum, and other select cryptocurrencies
        </h3>
        <p className=' text-lg md:text-xl text-[#003356]'>
          Buy as little as $30 worth to get started!
        </p>
      </div>
      <div className='w-full py-8 my-4'>
        <SwapContent />
      </div>
    </section>
  );
}

export default SwapLogic;
