import { urlFor } from "../../../lib/sanity/sanity.server";
export function ImageURL({ image, post, styles }) {
  if (!image || !post) {
    return;
  }
  return (
    <img
      src={urlFor(image.asset._ref).url()}
      alt={post.title}
      className={styles}
    />
  );
}

export function formatDate(date) {
  if(!date) return;
  return date.split("T")[0];
}

export function shortDescription(desc) {
  if(!desc) return
  const shorten = desc.split(" ");
  return shorten.length < 17
    ? shorten.join(" ")
    : shorten.slice(0, 17).join(" ") + " ...";
}

export function authorDetails(author, post) {
  if (!author || !post.author) {
    return;
  }
  return author
    .filter(({ _id }) => _id === post.author._ref)
    .map((cur) => {
      return (
        <div className='flex items-center' key={cur._id}>
          <div className='h-12 w-12 flex justify-center items-center rounded-full mr-3 my-2'>
            <ImageURL
              image={cur.image}
              post={post}
              styles={"w-full h-full object-cover rounded-full"}
            />
          </div>
          <span className='flex flex-col text-xs'>
            <span className='flex'>
              <h6 className='mr-1 text-[#111]'>{cur.name} </h6> |
              <p className='ml-1 text-[#111]'>{cur.role}</p>
            </span>
            <h6 className='text-[#111]'>{formatDate(post._createdAt)}</h6>
          </span>
        </div>
      );
    });
}

export function Paragraph({ author, post }) {
  if (!post || !author) {
    return;
  }
  return (
    <div className='py-1'>
      {authorDetails(author, post)}
      <h2 className='text-lg text-[#111] lg:text-2xl py-2 font-bold'>
        {post.title}
      </h2>
      <p className='text-md font-thin text-[#111]'>
        {shortDescription(post.description)}
      </p>
    </div>
  );
}

function NewsUI({ post_data, author }) {
  // const author = useContext(authorContext);
  return (
    <div className='w-[95%] flex justify-between md:flex-row px-3 flex-col mx-auto transition-all ease-in-out duratio-300ms hover:shadow-lg cursor-pointer hover:bg-[#4CAF50] hover:rounded'>
      <a href={`https://blog-rho-ashy.vercel.app/${post_data.slug.current}`}>
        <div className='w-full flex flex-col pt-3 '>
          <div className='w-full h-56 '>
            <ImageURL
              image={post_data.mainImage}
              post={post_data.title}
              styles={"object-cover h-full w-full"}
            />
          </div>
          <Paragraph author={author} post={post_data} />
        </div>
      </a>
    </div>
  );
}

export default NewsUI;
