import React from "react";
import Subheader from "../../components/pageheader";
import Image from '../../assets/spiralbg.png'
import { Renvest } from "./Product";
import { Renswap } from "./Product";


export default function Logic (){
    const styles={
        header:"font-bold text-white text-3xl lg:text-5xl",
        paragraph:''}
        const subStyles ={
            header:'font-bold text-center text-2xl text-[#003356] md:text-3xl lg:text-5xl',
            paragraph:'text-black text-base lg:text-lg text-center mt-4'
        }
    return <section>
            <div className="w-full h-72 flex justify-center items-center" id="search">
                
            <Subheader heading='About Rencoin' paragraph='' styles={styles}/>
            </div>
        <article className="container mx-auto">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">

     <div className="flex h-[30rem] flex-col justify-center py-4 items-center px-8 ">
        <h1 className="text-xl font-bold text-[#3a7700] text-left lg:text-3xl leading-10">Our mission is to create more economic freedom in the world</h1>
        <p className="text-base lg:text-lg mt-6 text-justify leading-8 lg:leading-10">We define economic freedom as the ability to make choices with respect to one’s personal resources, unencumbered by trusted third parties or borders or lack of access. We believe economic freedom is the foundation of peace and prosperity, and by creating more of it for people, we are reducing suffering in the world. Our mission is to help everyone, everywhere be more economically free</p>
        </div>   
       <div className="h-[30rem]">
        <img src={Image} alt="sideimage" className=" h-full w-full object-contain"/>
        </div>    
            </div>
            <div className="lg:w-2/3 h-56 mx-auto flex justify-center items-center">
            <Subheader heading='Our Products' paragraph='We define economic freedom as the ability to make choices with respect to one’s personal resources, unencumbered by trusted third parties or borders or lack of access.' styles={subStyles}/>
            </div>
            <div className="mt-10 px-8">
            <Renvest/>

            </div>
            <div className="mt-16 px-8">
            <Renswap/>
            </div>
        </article>
    </section>
}