import React from "react";
import Image from '../../assets/spiralbg.png'
import Button from "../../components/ui/button/Button";

function FirstSection() {
  return (
    <section className='h-full mb-14 lg:mb-32 w-screen firstSection'>
      <article className='container mx-auto'>
        <div className='w-full h-full flex flex-col lg:flex-row md:items-center justify-evenly items-center py-6'>
          <div className='w-3/4 md:h-56 lg:w-1/2 lg:h-[30rem] md:my-0'>
            <img src={Image} alt='first section' className='w-full h-full object-cover' />
          </div>
          <div className='lg:w-1/2 lg:h-[30rem]'>
            <div className='flex flex-col justify-evenly items-center h-full md:px-6'>
              <h1 className='text-xl sm:text-2xl md:text-4xl lg:text-5xl font-bold text-center lg:text-left lg:leading-[3.5rem] mt-6 text-[#3a7700] leading-[2.8rem] lg:px-0'>
                Driving the Future of Money
              </h1>
              <p className='text-sm sm:text-base md:text-base text-center lg:text-left font-medium leading-7 md:leading-8  text-[#3a7700] px-6 md:px-10 lg:px-0 my-3'>
                Tether tokens are the most widely adopted stablecoins, having
                pioneered the concept in the digital token space. A disruptor to
                the conventional financial system and a trailblazer in the
                digital use of traditional currencies, Tether Tokens support and
                empower growing ventures and innovation throughout the
                blockchain space. Tether Tokens exist as a digital token built
                on multiple blockchains.
              </p>
              {/* <button className='font-bold mb-4 font-bold py-4 px-10 w-full rounded-full border  border-white text-center cursor-pointer'>
                Learn how Rencoin works
              </button> */}
              <div className='h-20 mt-2 lg:mt-0 w-full flex justify-center items-center lg:justify-start'>
                <Button styles='px-2 w-56 lg:w-1/2 text-white text-bold py-4 rounded text-[#f1f7fd] bg-[#003356]'>
                  Learn How Rencoin Works
                </Button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default FirstSection;
