import React from "react";
import Button from "../../../components/ui/button/Button";
// import { FetchCoin } from "../../../api/api";
// import coin from "../../../assets/coin.webp";

export function Label({ styles, children }) {
  return <label className={styles}>{children}</label>;
}

export function Input({ styles }) {
  return <input className={styles} />;
}

export function Select() {
  return (
    <select class="form-select appearance-none
      block
      w-[90%] lg:w-96 py-4 px-2 rounded outline-0 px-3
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example">
        <option selected>Open this select menu</option>
       
        {/* <option value="2">Two</option> */}
        {/* <option value="3">Three</option> */}
    </select>
  );
}

// async function displayData (){
// return await FetchCoin().map((current_price, name,id, image, symbol)=> {
//   return  (<>
//     <option key={id} value={current_price}>
//   <img src={image} alt={name}/>
//   <span>{name}</span>
// </option>
//     </>
//   )
// })
// }

export function SwapInput({ data }) {
  const INPUT_STYLES =
    "w-[90%] lg:w-96 py-3 px-2 rounded outline-0 border bg-grey-700 rounded-lg shadow ";
  const LABEL_STYLES =
    "self-start w-[90%] lg:w-96 mx-auto text-base font-bold text-[#3a7700]";
  return (
    <div className='w-full h-96 flex flex-col items-center justify-evenly'>
      {/* <label className='self-start w-96 mx-auto text-base font-bold '>
          {data.label}
        </label> */}
      <Label styles={LABEL_STYLES}>{data.firstLabel}</Label>
      <Select />
      <Label styles={LABEL_STYLES}>{data.secondLabel}</Label>
      <div className='flex items-center w-[90%] lg:w-96 rounded px-2 justify-evenly bg-white rounded-lg shadow'>
        <Input styles={"w-full  lg:w-80 py-3 px-2 outline-0 rounded bg-transparent"} />
        <span className='font-bold text-base px-2'>REN</span>
      </div>
      <Label styles={LABEL_STYLES}>{data.thirdlabel}</Label>

      <Input styles={INPUT_STYLES} />
      <Button
        styles={
          "px-10 text-white text-bold py-3 w-[90%] lg:w-96 font-bold rounded bg-[#003356] transition-all ease-in duration-300ms hover:shadow-lg hover:scale-5"
        }>
        {data.button}
      </Button>
    </div>
  );
}

function SwapUI({ active }) {
  const BUY = {
    firstLabel: "I want to buy",
    secondLabel: "I want to spend",
    thirdlabel: "For this much",
    button: "BUY",
  };

  const SELL = {
    firstLabel: "I want to sell",
    secondLabel: "Amount",
    thirdlabel: "For this much",
    button: "SELL",
  };
  //   const slide = active ? "-translate-x-full" : "-translate-x-0";
  return (
    <div className='w-full overflow-hidden'>
      {active ? (
        <SwapInput data={{ ...SELL }} />
      ) : (
        <SwapInput data={{ ...BUY }} />
      )}
    </div>
  );
}

export default SwapUI;
