import React from "react";
import Homepage from "./pages/homepage";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout";
import FAQ from "./pages/faq";
import Whyrencoin from "./pages/whyrencoin";
import HowItWorks from "./pages/howitworks";
import AboutUs from "./pages/about-us";
import Contact from "./pages/contact";
// import News from "./components/pages/News";
// import LandingPage from "./components/pages/LandingPage";
// import Article from "./components/pages/Article";

import { useEffect } from "react";
import { useLocation } from "react-router";

export const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};


function App() {
  return (
   <ScrollToTop>
    <Layout>
      <main>
        <Routes>
          <Route path='/' element={<Homepage />}></Route>
          <Route path='/faq' element={<FAQ display='hidden'/>}></Route>
          <Route path='/why-rencoin' element={<Whyrencoin />}></Route>
          <Route path='/how-it-works' element={<HowItWorks />}></Route>
          <Route path='/about-us' element={<AboutUs />}></Route>
          <Route path='/contact-us' element={<Contact />}></Route>
        </Routes>
      </main>
    </Layout>
   </ScrollToTop>
  );
}

export default App;
