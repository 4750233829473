import HeroSection from "../../components/layout/HeroSection";
import FirstSection from "./FirstSection";
import Button from "../../components/ui/button/Button";
import News from "./news";
import FAQ from "./faq";
import Subscribe from "./subscribe";
import SwapLogic from "./rencoinswap/SwapLogic";
import { FetchCoin } from "../../api/api";
import { useEffect } from "react";

export function TopCard() {
  return (
    <section className='bg-[#f1f1f1]'>
      <article className=' container mx-auto mb-16 py-16'>
        <div className='w-[90%] mx-auto h-full md:h-96 rounded-2xl bg-image shadow-lg'>
          <div className='md:w-3/4 flex flex-col items-center justify-evenly mx-auto h-full py-10'>
            <h1 className='font-bold text-3xl md:text-5xl text-center text-white'>
              Widespread adoption
            </h1>
            <p className='text-center text-sm md:text-base text-white md:px-4 text-[#f1f1f1] py-6 md:py-0 leading-8 px-8 md:px-0'>
              From being the first, to the most used, stablecoin, and one of the
              most traded tokens by volume, Tether tokens have come a long way.
              Tether tokens are today the most widely adopted stablecoins across
              major exchanges, OTC desks, and wallets, including:
            </p>
            <Button
              styles={
                "w-56 text-white text-bold py-4 rounded text-[#f1f7fd] bg-[#003356] transition-all ease-in duration-300ms hover:shadow-lg hover:scale-1.5"
              }>
              Create an account
            </Button>
          </div>
        </div>
      </article>
    </section>
  );
}
const Homepage = () => {
  useEffect(() => {
    FetchCoin();
  }, []);
  return (
    <main className='min-h-screen w-screen'>
      <div>
        <HeroSection heading='Digital currency leads at market in the right' paragraph='Vestibulum faucibus eget erat eget pretium. Donec commodo
              convallis ligula, eget suscipit orci' homepage={true} />
        <TopCard />
        <FirstSection />
        <div className=' h-full'>
          <SwapLogic />
        </div>
        <FAQ />
        <div className='mt-26 py-4'>
          <News />
        </div>
        <div className='mt-26'>
          <Subscribe />
        </div>
      </div>
    </main>
  );
};

export default Homepage;
