export const FAQQuestions = [
    {
      id: 0,
      answer:
        "Launched in 2014, Tether is a blockchain-enabled platform designed to facilitate the use of fiat currencies in a digital manner. Tether works to disrupt the conventional financial system via a more modern approach to money. Tether has made headway by giving customers the ability to transact with traditional currencies across the blockchain, without the inherent volatility and complexity typically associated with a digital currency. As the first blockchain-enabled platform to facilitate the digital use of traditional currencies (a familiar, stable accounting unit), Tether has democratised cross-border transactions across the blockchain.",
      question: "What is Rencoin?",
    },
    {
      id: 1,
      question: "How do Rencoin work?",
      answer:
        "Tether tokens exist as digital tokens built on several leading blockchains, including Algorand, Avalanche, Bitcoin Cash’s Simple Ledger Protocol (SLP), Ethereum, EOS, Liquid Network, Omni, Polygon, Tezos, Tron, Solana and Statemine. These transport protocols consist of open source software that interface with blockchains to allow for the issuance and redemption of Tether tokens. Every Tether token is 100% backed by our reserves, which includes traditional currency and cash equivalents, and may include other assets and receivables from loans made by Tether to third parties.",
    },
    {
      id: 2,
      question: "What currencies and commodities does Tether support?",
      answer:
        "Tether supports US dollars (USD), euros, Mexican peso, British Pound Sterling, offshore Chinese yuan, and Gold, with the following Tether tokens, respectively: USD₮, EUR₮, MXN₮, Tether GBP, CNH₮ and XAU₮.",
    },
    {
      id: 3,
      question: "Who can use Rencoin?",
      answer:
        "Tether tokens enable businesses – including exchanges, wallets, payment processors, financial services and ATMs – to easily use fiat currencies on blockchains. Some of the largest businesses in the digital currency ecosystem have integrated Tether tokens.View industry supporters. Individuals can also use Tether-enabled platforms to transact with Tether tokens.",
    },
    {
      id: 4,
      question: "How to test Rencoin?",
      answer:
        "Tether tokens enable businesses – including exchanges, wallets, payment processors, financial services and ATMs – to easily use fiat currencies on blockchains. Some of the largest businesses in the digital currency ecosystem have integrated Tether tokens.View industry supporters. Individuals can also use Tether-enabled platforms to transact with Tether tokens.",
    },
    {
      id: 5,
      question: "How to test Rencoin?",
      answer:
        "Tether tokens enable businesses – including exchanges, wallets, payment processors, financial services and ATMs – to easily use fiat currencies on blockchains. Some of the largest businesses in the digital currency ecosystem have integrated Tether tokens.View industry supporters. Individuals can also use Tether-enabled platforms to transact with Tether tokens.",
    },
  ];