import FAQFuntions from "../homepage/faq/Accordion";
import { FAQQuestions } from "../../lib/data";
import { SearchIcon } from "../../components/ui/icon/Icon";
import Subheader from '../../components/pageheader'

import { useState } from "react";

function FAQ(){
    const [_value, setValue] = useState('')
function onChange (e){
    setValue(e.target.value.trim())
}

function filterQuestion(){
return FAQQuestions.filter((txt)=>txt.question.toLowerCase().includes(_value.toLowerCase()) )
}
const styles ={
    header:"font-bold text-2xl text-white md:text-3xl lg:text-4xl",
    paragraph:''
}
    return <div className=" mx-auto">
        <div className="h-72 flex flex-col justify-evenly items-center " id="search">
            <Subheader heading='Frequently Asked Questions' paragraph='' styles={styles}/>
            <div className=" mx-auto w-[90%] md:w-1/2 flex items-center px-6 rounded-full bg-white">
                <SearchIcon/>
            <input type='search' value={_value} onChange={onChange} className='w-full py-4 bg-transparent text-md px-2 focus:outline-none' placeholder="Search for FAQ"/>

            </div>
        </div>
              <div class='w-[95%] md:w-[60%] mx-auto my-10'>
        <FAQFuntions data={filterQuestion()}/>
</div>
    </div> 
}
export default FAQ;