import React from "react";
import HeroSection from "../../components/layout/HeroSection";
import { SharedSection } from "../../components/sharespagesection";
import News from "../homepage/news";
import Subscribe from "../homepage/subscribe";


export default function Logic(){
    return <section>
        <article>
        <HeroSection paragraph='Launched in 2014, Tether tokens (USD₮) pioneered the stablecoin model and are the most widely traded.' heading='What is Rencoin and How it works?' />
<SharedSection />
<News/>
        </article>
        <Subscribe/>
    </section> 
}

