export function Close({ close }) {
  return (
    <svg
      onClick={close}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke-width='1.5'
      stroke='currentColor'
      className='w-8 h-8 text-white'>
      <path
        stroke-linecap='round'
        stroke-linejoin='round'
        d='M6 18L18 6M6 6l12 12'
      />
    </svg>
  );
}

export function Menu() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke-width='1.5'
      stroke='currentColor'
      className='w-8 h-8 text-white'>
      <path
        stroke-linecap='round'
        stroke-linejoin='round'
        d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
      />
    </svg>
  );
}
export function CaretUp({ change }) {
  console.log(change);
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke-width='1.5'
      stroke='currentColor'
      class={`${change} transition-rotate w-6 h-6`}>
      <path
        stroke-linecap='round'
        stroke-linejoin='round'
        d='M4.5 15.75l7.5-7.5 7.5 7.5'
      />
    </svg>
  );
}
