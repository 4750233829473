import image from '../../assets/Bitmap.png'


export default function Cards(){
    return <div className="w-[25rem] my-4 h-96 rounded-xl transition-all ease-in-out duration-300 hover:shadow-xl">
        <div>
            <div className="w-full h-56">
                <img src={image} className='object-cover rounded' alt=''/>
            </div>
            <div className='p-4'>
                <h1 className='text-2xl '>Why use Rencoin</h1>
                <p className='text-base mt-4'>Launched in 2014, Tether tokens (USD₮) pioneered the stablecoin model and are the most widely traded. </p>
            </div>
        </div>
    </div>
}

