import React from "react";
import Cards from "./Cards";
import HeroSection from "../../components/layout/HeroSection";
import News from "../homepage/news";
import Subscribe from "../homepage/subscribe";
import { SharedSection } from "../../components/sharespagesection";
function Logic (){
   
    return <>
    <HeroSection heading='Why use Rencoin' paragraph='Launched in 2014, Tether tokens (USD₮) pioneered the stablecoin model and are the most widely traded. '/>
   <section>
    <article className="container mx-auto">
        <SharedSection heading='Whether it is for personal use or business purposes, Tether tokens offer many
benefits as the most stable, liquid and trusted stablecoin.' />
        
        <div className="py-4 mt-6 md:mt-14 flex flex-col flex-wrap justify-evenly items-center lg:flex-row lg:justify-evenly lg:items-center">
            <Cards/>
            <Cards/>
            <Cards/>
            <Cards/>
            <Cards/>
            <Cards/>
        </div>
        <div>
<News/>
        </div>
    </article>
            <Subscribe/>
   </section>
    </>
}
export default Logic;