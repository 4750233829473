import background from "../../assets/crypto_banner.svg";
import Button from "../ui/button/Button";
import Typewriter from "typewriter-effect";

function HeroSection({heading,paragraph, homepage}) {
  return (
    <main
      className='h-full lg:h-[70vh]'
      style={{
        backgroundImage: "linear-gradient(90deg,#3a7700,#003356)",
      }}>
      <div className='container h-full mx-auto lg:flex justify-center items-center lg:px-10'>
        <div className='w-full md:w-[90%] md:mx-auto lg:mx-0 lg:w-[50%]  flex flex-col justify-evenly'>
          <div className='h-full px-4 lg:px-6 flex flex-col justify-center '>
            <h1 className='text-3xl py-4 md:py-0 md:text-5xl text-center lg:text-left lg:leading-[3.5rem] text-white leading-[2.8rem] px-1 lg:px-0 text-[#f1f7fd] font-bold'>
              {/* {} */}
              {heading}
              {homepage &&
                <Typewriter
                  options={{
                    strings: ["Amount", "Coin"],
                    deleteChars: 1,
                    // strings:'A simple yet powerful native javascript',
                    autoStart: true,
                    loop: true,
                  }}
                />
              }
            </h1>
          </div>
          <div className='h-30 lg:h-38 lg:p-6 lg:my-3 lg:my-0'>
            <p className='text-md text-center lg:text-left leading-7 lg:leading-8 text-[#f1f7fd] px-6 md:px-2'>
              {/*  */}
              {paragraph}
            </p>
          </div>
          <div className='h-20 px-6 mt-2 lg:mt-0 w-full flex justify-center items-center lg:justify-start'>
            <Button
              styles={
                "w-56 text-white text-bold py-4 rounded text-[#f1f7fd] bg-[#003356] font-bold transition-all ease-in duration-300ms hover:shadow-lg hover:scale-1.5"
              }>
              Get Started
            </Button>
          </div>
        </div>
        <div className='w-full md:w-[50%] lg:h-1/2 h-full flex flex-col justify-evenly md:mx-auto lg:mx-0 mt-10 lg:mt-0'>
          <img src={background} alt='' />
        </div>
      </div>
    </main>
  );
}

export default HeroSection;
