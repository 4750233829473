import axios from "axios";

export const postQuery = `*[_type == 'post']{categories,_id,mainImage, title, description, author, slug, _createdAt}`;
// export const categoryQuery = `*[_type == "category"]`;
export const authorQuery = `*[_type == "author"]{_id,name,image,role}`;

export async function FetchCoin() {
  try {
    const data = await axios(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
    );
    return data;
  } catch (error) {
    console.log("Network error"+ error);
  }
}
