import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Close, Menu } from "../../assets/svg/Svg";
import Logo from "../../assets/logo.png";

const links = [
  { id: 0, path: "/", name: "Home" },
  { id: 1, path: "/why-rencoin", name: "Why Rencoin?" },
  { id: 2, path: "/how-it-works", name: "How it works" },
  { id: 3, path: "/", name: "Buy" },
  // { id: 4, path: "/products", name: "Products" },
  
];

export function LinksFunt({ textSize }) {
  return links.map(({ id, path, name }) => {
    return (
      <>
      <li key={id} className='font-bold text-[#f1f7fd]'>
        <Link
          className={`${textSize} active:text-gray-400 font-bold text-xl focus:text-gray-400 hover:text-gray-400`}
          to={`${path}`}>
          {name}
        </Link>
      </li>
      
            </>
    );
  });
}

export function MobileNav() {
  const [open, setOpen] = useState(false);
  const slide = open ? "left-0 md:left-1/2" : "left-full";
  return (
    <div>
      <div>
        <div className='h-20 flex justify-between items-center px-8 lg:hidden '>
          <div className=' w-24 lg:w-36'>
            <img src={Logo} alt='Rencoin' />
          </div>
          <div
            className='cursor-pointer'
            onClick={() => {
              setOpen(!open);
            }}>
            <Menu />
          </div>
        </div>
      </div>
      <ul
        className={` w-full h-screen lg:hidden transition-all ease duration-300 fixed top-0 left-0 ${slide} z-50`}
        style={{ backgroundColor: "rgba(0, 51, 86, 0.98)" }}>
        <li className=' h-20 flex justify-between items-center px-6'>
          <li>LOGO</li>
          <li className='cursor-pointer'>
            <Close close={() => setOpen(!open)} />
          </li>
        </li>
        <ul className='flex flex-col items-start justify-evenly h-80  px-6'>
          <LinksFunt textSize={"text-xl text-[#f1f7fd]"} />
          <li className="text-lg text-[#f1f7fd]">
        <a href="https://blog-rho-ashy.vercel.app/" rel="noreferrer" className="active:text-gray-300 text-xl focus:text-gray-500 hover:text-gray-400" target='_blank'>News</a>
      </li>
        </ul>
       
      </ul>
    </div>
  );
}

export function DesktopNav() {
  return (
    <ul className='w-full h-full justify-between items-center hidden lg:flex '>
      <li className="w-1/2 pl-16">
        <img src={Logo} alt='logo' />
      </li>
      <li className='flex justify-evenly items-center w-[50%]  px-6'>
        <LinksFunt textSize={"text-lg text-[#f1f7fd]"} />
        <li className="text-lg text-[#f1f7fd]">
        <a href="https://blog-rho-ashy.vercel.app/" rel="noreferrer" className="active:text-gray-300 font-bold text-xl focus:text-gray-500 hover:text-gray-400" target='_blank'>Blog</a>
      </li>
      </li>
      
    </ul>
  );
}
