import React from "react";
import FAQFuntions from "./Accordion";
import { LinkButton } from "../../../components/ui/button/Button";
import { FAQQuestions } from "../../../lib/data";
import { LimitData } from "../../../lib/utils";

function FAQ({display}) {
  return (
    <section className='faq w-full h-full py-6 mt-2 lg:mt-16 '>
      <div className='px-2 w-full mb-16'>
        <h1 className='heading text-center text-2xl md:text-4xl font-bold'>
          Frequently Asked Questions
        </h1>
      </div>
      <div class='w-[95%]  md:w-[60%] mx-auto mt-8 ' id='accordionFlushExample'>
        <FAQFuntions data={LimitData(FAQQuestions)}  />
        <div className={`${display} w-full md:w-56 mx-auto btn rounded-lg flex justify-center items-center mt-12 mb-4 py-2`}>
          <LinkButton path={"/faq"} name='Read all FAQs' />
        </div>
      </div>
    </section>
  );
}

export default FAQ;
